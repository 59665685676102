import React from "react"
import { ThemeProvider, CSSReset } from "@chakra-ui/react"

import logo from "./logo.svg"
import "./App.css"
import AppRoutes from "./Routes"
import customTheme from "./customTheme"

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <AppRoutes />
    </ThemeProvider>
  )
}

export default App
