import React from "react"
import { VStack, Heading, Text, Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export default function About() {
  return (
    <VStack
      pt={{ base: 10, md: 20 }}
      spacing={{ base: 10, md: 20 }}
      px={{ base: 3, sm: 6 }}
    >
      <Heading>About</Heading>
      <VStack alignItems="flex-start" spacing={{ base: 6, md: 12 }}>
        <Heading size="sm">Our Story</Heading>
        <Text>
          Think of Me (TOM) was started in 2020 by David Corbitt. TOM was
          inspired by David's manager at Qualtrics, who created a simple
          Raspberry Pi version of TOM for his wife. David was captured by the
          idea of perpetually evolving connection, but did not begin work on TOM
          in earnest until 2022, as a gift for his fiance who was working in
          Alaska.
        </Text>
      </VStack>
      <Text>
        Learn how we protect your{" "}
        <Button variant="link">
          <Link to="/privacy">privacy</Link>
        </Button>
        .
      </Text>
    </VStack>
  )
}
