import React from "react"
import { HStack, VStack, Text, Heading } from "@chakra-ui/react"

export default function Home() {
  return (
    <VStack
      pt={{ base: 10, md: 20 }}
      spacing={{ base: 10, md: 20 }}
      px={{ base: 3, sm: 6 }}
    >
      <Heading>Get the App</Heading>
      <Text>
        Think of Me is currently available only in mobile form. You can find it
        on either the Google Play Store or App Store.
      </Text>
    </VStack>
  )
}
