import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"

import { NavBar } from "./components"
import { Home, About, Privacy } from "./views"

export default function AppRoutes() {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  )
}
