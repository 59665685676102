import React from "react"
import { Heading, VStack, Text, Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export default function Privacy() {
  return (
    <VStack
      pt={{ base: 10, md: 20 }}
      spacing={{ base: 10, md: 20 }}
      px={{ base: 3, sm: 6 }}
    >
      <Heading>Privacy</Heading>
      <VStack>
        <Text>
          We take your privacy seriously. Your notes are stored in protected
          databases. We are careful to keep your information private and secure.
        </Text>
      </VStack>
      <Text>
        Learn more about the{" "}
        <Button variant="link">
          <Link to="/about">team</Link>
        </Button>
        .
      </Text>
    </VStack>
  )
}
