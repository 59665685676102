import React from "react"
import { Link } from "react-router-dom"

import { Heading, HStack, Text, Button } from "@chakra-ui/react"

export default function NavBar() {
  return (
    <HStack
      h={50}
      bgColor="blue.600"
      borderBottom="1px solid"
      px={5}
      justifyContent="space-between"
    >
      <Logo />
      <HStack spacing={5}>
        <Link to="/">
          <Button variant="link" color="white" boxShadow="none !important">
            Home
          </Button>
        </Link>

        <Link to="/about">
          <Button variant="link" color="white" boxShadow="none !important">
            About
          </Button>
        </Link>

        <Link to="/privacy">
          <Button variant="link" color="white" boxShadow="none !important">
            Privacy
          </Button>
        </Link>
      </HStack>
    </HStack>
  )
}

const Logo = () => {
  return (
    <Link to="/">
      <HStack
        bgColor="white"
        borderRadius="md"
        p="2"
        spacing={1}
        cursor="pointer"
      >
        <Text textColor="#77ccff">T</Text>
        <Text textColor="grey">O</Text>
        <Text textColor="#ffbbbb">M</Text>
      </HStack>
    </Link>
  )
}
